import './Settings.css'

import { Link } from 'react-router-dom'
import {
  useState,
  useCallback,
} from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import UserInfo from '../../store/UserInfo'
import { BUTTON_MODES, show } from '../../view/PopupEvent'
import { UserInfoDisplay } from './component/UserInfoDisplay'
import { SettingBlock } from './component/SettingBlock'
import { SettingRow } from './component/SettingRow'
import { IncreaseMemberLimit } from './component/IncreaseMemberLimit'
import Api from '../../api/Api'
import { CHANGE_MEMBER_LIMIT } from '../../constant/Endpoints'
import { sentryLog } from "../../util/sentry";
import { useTextInput } from '../../hook/useTextInput'

export const Settings = observer(() => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const [displayName, handleDisplayNameChange, displayNameRef, setDisplayName] = useTextInput()

  const [oldPwd, handleOldPwdChange, oldPwdRef, setOldPwd] = useTextInput()
  const [newPwd, handleNewPwdChange, newPwdRef, setNewPwd] = useTextInput()
  const [newPwdRe, handleNewPwdReChange, newPwdReRef, setNewPwdRe] = useTextInput()

  const [newEmail, handleNewEmailChange, emailRef, setEmail] = useTextInput()

  const applyDisplayName = useCallback(async () => {
    setLoading(true)
    const ok = await UserInfo.update({ displayName: displayNameRef.current })
    if (ok) {
      setDisplayName('')
    }
    setLoading(false)
  }, [displayNameRef, setDisplayName])

  const applyNewPwd = useCallback(async () => {
    if (newPwdRef.current !== newPwdReRef.current) {
      // error
    } else if (!UserInfo.isManager) {
      // only manager can change password
      show({
        content: t('notification.pwdPem'),
        btnMode: BUTTON_MODES.OK,
      })
    } else {
      setLoading(true)
      const ok = await UserInfo.updatePassword({
        oldPassword: oldPwdRef.current,
        newPassword: newPwdRef.current,
      })

      if (ok) {
        setOldPwd('')
        setNewPwd('')
        setNewPwdRe('')

        // show notification
        await show({
          content: t('notification.pwdUpdated'),
          btnMode: BUTTON_MODES.OK,
        })
      }
      setLoading(false)
    }
  }, [newPwdReRef, newPwdRef, oldPwdRef, setNewPwd, setNewPwdRe, setOldPwd, t])

  const applyNewEmail = useCallback(async () => {
    setLoading(true)
    try {
      const ok = await UserInfo.updateEmail({
        email: emailRef.current,
        password: oldPwdRef.current,
      })

      if (ok) {
        setEmail('')
        setOldPwd('')

        await show({
          content: t('メールアドレス変更完了'),
          btnMode: BUTTON_MODES.OK,
        })
      } else {
        throw t('不明なエラー')
      }
    } catch (error: any) {
      // log to sentry
      sentryLog(error)
      await show({
        title: t('エラーが発生しました'),
        content: error || t('不明なエラー'),
        btnMode: BUTTON_MODES.OK,
      })
    } finally {
      setLoading(false)
    }
  }, [emailRef, oldPwdRef, setEmail, setOldPwd, t])

  const resumePlan = async () => {
    setLoading(true)
    const ok = await show({
      content: <div>
        <p>{t('現在、キャンセル状態にあるプランを再開します。')}</p>
        <p>{t('よろしいですか？')}</p>
      </div>,
      btnMode: BUTTON_MODES.OK_CANCEL,
    })
    if (ok) {
      const [ok, msg] = await UserInfo.resumeSubscription()

      if (ok) {
        await show({
          content: t('プラン契約を再開しました。'),
          btnMode: BUTTON_MODES.OK,
        })
      } else {
        await show({
          content: t('再開出来ませんでした -- ') + msg,
          btnMode: BUTTON_MODES.OK,
        })
      }
    }
    setLoading(false)
  }

  const cancelPlan = async () => {
    setLoading(true)
    const ok = await show({
      content: <div>
        <p>{t('現在契約中のプランを解約します。')}</p>
        <p>{t('次の契約更新日に無料プランに切り替わります。')}</p>
        <p>{t('よろしいですか？')}</p>
      </div>,
      btnMode: BUTTON_MODES.OK_CANCEL,
    })
    if (ok) {
      const [ok, msg] = await UserInfo.unsubscribe()

      if (ok) {
        await show({
          content: t('プランを解約しました。'),
          btnMode: BUTTON_MODES.OK,
        })
      } else {
        await show({
          content: t('解約出来ませんでした -- ') + msg,
          btnMode: BUTTON_MODES.OK,
        })
      }
    }
    setLoading(false)
  }

  const handleMemberLimitPurchase = useCallback((memCount: number) => {
    show({
      content: t('処理中…'),
      btnMode: BUTTON_MODES.NONE,
      staticBackdrop: true,
    })

    Api.post(CHANGE_MEMBER_LIMIT, { memCount }).then((response) => {
      console.log(response)
      if (response.data?.success) {
        // refresh user info
        UserInfo.refreshTime()

        show({
          content: t('購入が完了しました。'),
          btnMode: BUTTON_MODES.OK,
        })
      } else {
        show({
          content: t('エラーが発生しました'),
          btnMode: BUTTON_MODES.OK,
        })
      }
    }).catch((error) => {
      console.warn(error)
      // try to get error message from axios error object
      const msg = error.response?.data?.message || error.message
      show({
        content: t(msg || 'エラーが発生しました'),
        btnMode: BUTTON_MODES.OK,
      })
    })
  }, [t])

  return (
    <div className='meeting-list container p-4 p-md-4 text-donut'>
      <div className='row mb-3'>
        <div className='col-auto h1 text-primary mb-0'>
          <b>{t('各種設定')}</b>
        </div>
        <div className='col text-donut'>
          {t('内容を入力したら、項目ごとに「変更する」ボタンをクリックしてください')}
        </div>
      </div>
      <div className='row mx-md-5'>
        <div className={`col donut-box${loading ? ' disabled' : ''}`} style={{ maxWidth: 800 }}>
          <SettingBlock title={t('現在のプラン設定')} initialState={true}>
            <UserInfoDisplay />
          </SettingBlock>
          {UserInfo.isUnlimitedLPlan() && (
            <SettingBlock title={t('メンバー数を増やす')}>
              <IncreaseMemberLimit onItemClick={handleMemberLimitPurchase} />
            </SettingBlock>
          )}
          <SettingBlock title={t('お名前の登録・変更')}>
            <SettingRow title={t('現在のお名前')} value={UserInfo.displayName} />
            <SettingRow title={t('新しいお名前')} value={displayName} onChange={handleDisplayNameChange} onClick={applyDisplayName} />
          </SettingBlock>
          <SettingBlock title={t('パスワードの変更')}>
            <SettingRow title={t('現在のパスワード')} value={oldPwd} onChange={handleOldPwdChange} type='password' />
            <SettingRow title={t('新しいパスワード')} value={newPwd} onChange={handleNewPwdChange} type='password' />
            <SettingRow title={t('再入力')} value={newPwdRe} onChange={handleNewPwdReChange} type='password' onClick={applyNewPwd} />
          </SettingBlock>
          <SettingBlock title={t('登録メールアドレスの変更')}>
            <SettingRow title={t('現在のメールアドレス')} value={UserInfo.email} />
            <SettingRow title={t('現在のパスワード')} value={oldPwd} onChange={handleOldPwdChange} type='password' onClick={applyNewEmail} />
            <SettingRow title={t('新しいメールアドレス')} value={newEmail} onChange={handleNewEmailChange} />
          </SettingBlock>
          <SettingBlock title={t('辞書')} link="/settings/sttDictionaries" />
          {UserInfo.isManager ? (
            <SettingBlock title={t('契約プランの変更・設定')}>
              <div className='py-3 row justify-content-center'>
                {/* {UserInfo.currentSub && UserInfo.currentSub.plan.productCode !== 'FREE' && !UserInfo.currentSub.isBeingCancelled && (
                  <div className='col-auto'>
                    <Link to='/subscribe' className='my-1 btn btn-primary'>
                      {t('有料プランの変更')}
                    </Link>
                  </div>
                )} */}
                {(UserInfo.currentSub == null) || (UserInfo.currentSub.plan.amountJpy <= 0) ? (
                  <div className='col-auto'>
                    <Link to='/subscribe' className='my-1 btn btn-primary'>
                      {t('サービスを契約する')}
                    </Link>
                  </div>
                ) : (
                  UserInfo.currentSub.isBeingCancelled ? (
                    <div className='col-auto'>
                      <button className='my-1 btn btn-dark rounded-pill' onClick={resumePlan}>
                        {t('有料プランの再開')}
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className='col-auto'>
                        <Link to='/subscribe' className='my-1 btn btn-primary'>
                          {t('有料プランの変更')}
                        </Link>
                      </div>
                      <div className='col-auto'>
                        <button className='my-1 btn btn-dark rounded-pill' onClick={cancelPlan}>
                          {t('有料プランの解約')}
                        </button>
                      </div>
                    </>
                  )
                )}
              </div>
            </SettingBlock>
          ) : null}
        </div>
      </div>
    </div>
  )
})
