import { ChangeEvent, FormEvent, useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import UserInfo, { TMember } from "../../store/UserInfo"
import { BUTTON_MODES, SIZE_MODE, hide, show } from "../../view/PopupEvent"
import Api from "../../api/Api"
import { CREATE_MEMBER, DELETE_MEMBER, REACTIVATE_MEMBER, UPDATE_MEMBER, UPDATE_MEMBER_PASSWORD } from "../../constant/Endpoints"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import { sentryLog } from "../../util/sentry";

type HandleInput = (e: ChangeEvent<HTMLInputElement>) => void

const DeleteUserModal = ({ member }: { member: TMember }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="mb-2 pb-2">
        <p className="m-0 p-0">{t('以下のメンバーを無効化します。宜しいですか？')}</p>
        <div className="text-donut">{t('無効化されたメンバーはログイン出来なくなります。')}</div>
        <div className="text-donut">{t('認証待ちの場合、無効化されていても認証自体は可能です。')}</div>
        <div className="text-donut">{t('認証の有無に関わらず、後から再度有効化が可能です。')}</div>
      </div>

      <h5>{member.displayName}</h5>
      <h6 className="text-donut">{member.email}</h6>
    </div>
  )
}

const ResendConfirmationEmailModal = ({ member }: { member: TMember }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="mb-2 pb-2">
        <p className="m-0 p-0">{t('以下のメンバーに再認証用のメールアドレスを送信します。')}</p>
      </div>

      <h5>{member.displayName}</h5>
      <h6 className="text-donut">{member.email}</h6>
    </div>
  )
}


const ReactivateUserModal = ({ member }: { member: TMember }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="mb-2 pb-2">
        <p className="m-0 p-0">{t('以下のメンバーを再度有効化します。宜しいですか？')}</p>
        <div className="text-donut">{t('メンバーが有効な間、ログイン及び自らの議事録管理を行うことが可能です。')}</div>
      </div>

      <h5>{member.displayName}</h5>
      <h6 className="text-donut">{member.email}</h6>
    </div>
  )
}


const EditUserModal = ({ member }: { member: TMember }) => {
  const { t } = useTranslation()

  const [displayName, setDisplayName] = useState(member.displayName)
  const onChangeDisplayName: HandleInput = e => setDisplayName(e.currentTarget.value)

  const [email, setEmail] = useState(member.email)
  const onChangeEmail: HandleInput = e => setEmail(e.currentTarget.value)

  const [newPassword, setNewPassword] = useState('')
  const onChangeNewPassword: HandleInput = e => setNewPassword(e.currentTarget.value)

  const [newPasswordSecond, setNewPasswordSecond] = useState('')
  const onChangeNewPasswordSecond: HandleInput = e => setNewPasswordSecond(e.currentTarget.value)

  const [description, setDescription] = useState<string>(member.description || '')
  const onChangeDescription: HandleInput = e => setDescription(e.currentTarget.value)

  const onInfoSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.currentTarget.checkValidity()) {
      const param: any = {}
      if (displayName && member.displayName !== displayName) {
        param.displayName = displayName
      }
      if (email && member.email !== email) {
        param.email = email
      }
      if (description && member.description !== description) {
        param.description = description
      }

      if (Object.keys(param).length === 0) {
        return hide()
      }

      Api.post(UPDATE_MEMBER.replace("{id}", member.id), param)
        .then(resp => {
          if (resp.data?.success) {
            UserInfo.fetchMembers()
          }
          hide()
        }).catch(e => {
          // log to sentry
          sentryLog(e)
          if (e?.response?.data?.message != null) {
            alert(e?.response?.data?.message)
          } else {
            alert(t('エラーが発生しました。'))
          }
          hide()
        })
    }
  }

  const onPasswordSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (newPassword === newPasswordSecond && e.currentTarget.checkValidity()) {
      Api.post(UPDATE_MEMBER_PASSWORD.replace("{id}", member.id), {
        password: newPassword
      })
        .then(resp => {
          if (resp.data?.success) {
            UserInfo.fetchMembers()
            hide()
          }
        })
    }
  }

  return (
    <div>
      <form onSubmit={onInfoSubmit} noValidate className="mb-3">
        <h4>{t('基礎情報')}</h4>
        <div className="mb-3 row">
          <label htmlFor="title" className="col-sm-4 col-form-label">
            {t('お名前')}
          </label>
          <div className="col-sm-8">
            <input
              required
              id="title"
              type="text"
              className="form-control"
              value={displayName}
              onChange={onChangeDisplayName}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="allocatedTime" className="col-sm-4 col-form-label">
            {t('メール')}
          </label>

          <div className="col-sm-8">
            <input
              required
              id="email"
              className="form-control"
              value={email}
              onChange={onChangeEmail}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="description" className="col-sm-4 col-form-label">
            {t('備考')}
          </label>

          <div className="col-sm-8">
            <input
              type="text"
              id="description"
              className="form-control"
              value={description}
              onChange={onChangeDescription}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="flex-fill"></div>
          <button
            type="submit"
            className="btn btn-primary"
          >
            {t('更新')}
          </button>
        </div>
      </form>

      <form onSubmit={onPasswordSubmit} noValidate>
        <h4>
          {t('パスワード')}
        </h4>
        <div className="mb-3 row">
          <label htmlFor="password" className="col-sm-4 col-form-label">
            {t('新パスワード')}
          </label>

          <div className="col-sm-8">
            <input
              required
              type="password"
              id="password"
              className="form-control"
              minLength={6}
              maxLength={72}
              value={newPassword}
              onChange={onChangeNewPassword}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="password-again" className="col-sm-4 col-form-label">
            {t('新パスワード')}<br /> ({t('再入力')})
          </label>

          <div className="col-sm-8">
            <input
              required
              type="password"
              id="password-again"
              className="form-control"
              minLength={6}
              maxLength={72}
              value={newPasswordSecond}
              onChange={onChangeNewPasswordSecond}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="flex-fill"></div>
          <button
            type="submit"
            className="btn btn-primary"
          >
            {t('更新')}
          </button>
        </div>
      </form>
    </div>
  )
}

const showApiError = (error: any) => {
  // log to sentry
  sentryLog(error)
  show({
    content: i18next.t(error?.response?.data?.message || '不明なエラー'),
    btnMode: BUTTON_MODES.OK,
  })
}

const CreateUserModal = () => {
  const { t } = useTranslation()

  const [displayName, setDisplayName] = useState('')
  const onChangeDisplayName: HandleInput = e => setDisplayName(e.currentTarget.value)

  const [email, setEmail] = useState('')
  const onChangeEmail: HandleInput = e => setEmail(e.currentTarget.value)

  const [password, setPassword] = useState('')
  const onChangePassword: HandleInput = e => setPassword(e.currentTarget.value)

  const [passwordSecond, setPasswordSecond] = useState('')
  const onChangePasswordSecond: HandleInput = e => setPasswordSecond(e.currentTarget.value)

  const [description, setDescription] = useState('')
  const onChangeDescription: HandleInput = e => setDescription(e.currentTarget.value)

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (password === passwordSecond && e.currentTarget.checkValidity()) {
      Api.post(CREATE_MEMBER, {
        displayName, email, password, description,
        authenOrigin: process.env.REACT_APP_AUTHDOMAIN,
      }).then(resp => {
        if (resp.data?.success) {
          UserInfo.fetchMembers()
          hide()
        }
      }).catch((e) => {
        // if (e?.response?.data?.message != null) {
        //   alert(e.response.data.message)
        // } else {
        //   alert("エラーが発生しました。")
        // }
        // hide()
        showApiError(e)
      })
    } else {
      // Deny.
    }
  }

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="mb-3 row">
        <label htmlFor="title" className="col-sm-4 col-form-label">
          {t('お名前')}
        </label>
        <div className="col-sm-8">
          <input
            required
            id="title"
            type="text"
            className="form-control"
            value={displayName}
            onChange={onChangeDisplayName}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="allocatedTime" className="col-sm-4 col-form-label">
          {t('メール')}
        </label>

        <div className="col-sm-8">
          <input
            required
            id="email"
            className="form-control"
            value={email}
            onChange={onChangeEmail}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="password" className="col-sm-4 col-form-label">
          {t('パスワード')}
        </label>

        <div className="col-sm-8">
          <input
            required
            type="password"
            id="password"
            className="form-control"
            minLength={6}
            maxLength={72}
            value={password}
            onChange={onChangePassword}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="password-again" className="col-sm-4 col-form-label">
          {t('パスワード')}<br /> ({t('再入力')})
        </label>

        <div className="col-sm-8">
          <input
            required
            type="password"
            id="password-again"
            className="form-control"
            minLength={6}
            maxLength={72}
            value={passwordSecond}
            onChange={onChangePasswordSecond}
          />
        </div>
      </div>

      <div className="mb-3 row">
        <label htmlFor="description" className="col-sm-4 col-form-label">
          {t('備考')}
        </label>

        <div className="col-sm-8">
          <input
            type="text"
            id="description"
            className="form-control"
            value={description}
            onChange={onChangeDescription}
          />
        </div>
      </div>

      <div className="d-flex">
        <div className="flex-fill"></div>
        <button
          type="submit"
          className="btn btn-primary"
        >
          {t('作成')}
        </button>
      </div>
    </form>
  )
}

const makeDeleteModal = (tMember: TMember) => {
  show({
    title: i18next.t("メンバーを無効化"),
    content: <DeleteUserModal member={tMember} />,
    btnMode: BUTTON_MODES.OK_CANCEL,
    okAction: async () => {
      await Api.delete(DELETE_MEMBER.replace("{id}", tMember.id))
        .then(resp => {
          if (resp.data.success) {
            return UserInfo.fetchMembers()
          }
        })
        .catch(e => {
          // log to sentry
          sentryLog(e)
          if (e?.response?.data?.message != null) {
            alert(e.response.data.message)
          } else {
            alert(i18next.t('エラーが発生しました。'))
          }
        })
    }
  })
}

const makeReactivateModal = (tMember: TMember) => {
  show({
    title: i18next.t("メンバーを再度有効化"),
    content: <ReactivateUserModal member={tMember} />,
    btnMode: BUTTON_MODES.OK_CANCEL,

    okAction: async () => {
      Api.post(REACTIVATE_MEMBER.replace("{id}", tMember.id)).then((result) => {
        if (result.data.success) {
          UserInfo.fetchMembers()
        }
      }).catch((error) => {
        showApiError(error)
      })
    }
  })
}

const makeResendConfirmationEmailModal = (tMember: TMember) => {
  show({
    title: i18next.t("再度メールアドレス認証のリンクを送信"),
    content: <ResendConfirmationEmailModal member={tMember} />,
    btnMode: BUTTON_MODES.OK_CANCEL,

    okAction: async () => {
      const [ok, error] = await UserInfo.resendConfirmRequest(tMember.email)
      console.log(ok, error)
      if (ok) {
        alert(i18next.t('メールを再送信しました。'))
      } else {
        alert(error || i18next.t('エラーが発生しました。'))
      }
    }
  })
}

const makeEditModal = (tMember: TMember) => {
  show({
    title: i18next.t("メンバーを編集"),
    content: <EditUserModal member={tMember} />,
    size: SIZE_MODE.LARGE,
    btnMode: BUTTON_MODES.NONE
  })
}

const openModal = () => show({
  title: i18next.t("新しいメンバーを作成"),
  content: <CreateUserModal />,
  size: SIZE_MODE.LARGE,
  btnMode: BUTTON_MODES.NONE,
})

const MemberRow = ({ member, index }: { member: TMember, index: number }) => {
  const { t } = useTranslation()
  return (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{member.displayName}</td>
      <td>{member.email}</td>
      <td>{member.description}</td>
      <td>
        {
          member.isConfirmed ? (
            member.isDeleted ? t(
              "停止中"
            ) : t(
              "稼働中"
            )
          ) : (
            member.isDeleted ? t(
              "停止中（未認証）"
            ) : t(
              "認証待ち"
            )
          )
        }
      </td>
      <td>
        {
          member.isDeleted ? (
            <button className="btn btn-sm btn-primary" onClick={() => makeReactivateModal(member)}>
              {t('再有効化')}
            </button>
          ) : (
            <button className="btn btn-sm btn-warning" onClick={() => makeDeleteModal(member)}>
              {t('使用停止')}
            </button>
          )
        }
        {
          member.isConfirmed ? (
            <button className="mx-1 btn btn-sm btn-success" onClick={() => makeEditModal(member)}>
              {t('編集')}
            </button>
          ) : (
            <button
              disabled={member.isDeleted}
              className={"mx-1 btn btn-sm " + (member.isDeleted ? "btn-outline-secondary" : "btn-info")}
              onClick={() => makeResendConfirmationEmailModal(member)}
            >
              {t('認証リンクを再送信')}
            </button>
          )
        }
      </td>
    </tr>
  )
}

export const MemberList = observer(() => {
  const { t } = useTranslation()

  useEffect(() => {
    UserInfo.fetchMembers()
  }, [])

  return (
    <div className='container p-4 p-md-4 text-donut'>
      <div className='row mb-3'>
        <div className='col-auto h1 text-primary mb-0'>
          <b>{t('メンバー一覧')}</b>
        </div>
        <div className='col text-donut'>
          {t('内容を入力したら項目ごとに「変更する」ボタンをクリックしてください')}
        </div>
      </div>

      <div>
        <button className="btn btn-primary" onClick={openModal}>
          {t('作成')}
        </button>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t('お名前')}</th>
            <th scope="col">{t('メールアドレス')}</th>
            <th scope="col">{t('備考')}</th>
            <th scope="col">{t('ステータス')}</th>
            <th scope="col">{t('アクション')}</th>
          </tr>
        </thead>
        <tbody>
          {UserInfo.members.map((data, idx) => <MemberRow member={data} index={idx} key={data.id} />)}
        </tbody>
      </table>
    </div>
  )
})
